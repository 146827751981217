const linksList = [
  {
    text: 'Обзоры',
    url: '/info/obzory/',
  },
  {
    text: 'Видео',
    url: '/info/video/',
  },
  {
    text: 'Статьи',
    url: '/info/stati/',
  },
  {
    text: 'Вебинар',
    url: '/info/news/search?tag=Вебинар',
  },
  {
    text: 'Семинар',
    url: '/info/news/search?tag=Семинар',
  },
  {
    text: 'Форум',
    url: '/info/news/search?tag=Форум',
  },
  {
    text: 'Новости',
    url: '/info/news/',
  },
  {
    text: 'Все новости',
    url: '/info/news/',
  },
];

export const useInfoLink = (text?: string) => {
  let link = '/';
  if (text === undefined) return '/';
  linksList.forEach((el) => {
    if (el.text.toLowerCase() === text.toLowerCase()) {
      link = el.url;
    }
  });
  return link;
};
